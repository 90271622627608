<template>
  <div class="nav-form">
    <div class="d-md-none">
      <b-link class="lnr lnr-magnifier" @click.prevent="toggleSearch"></b-link>
    </div>
    <div class="search-group" :class="{ show: isSearchGroupShown }">
      <b-btn v-on:click="goClose()" class="close-btn" variant="light" squared
        >X</b-btn
      >
      <b-form @submit.prevent="goSearch" aria-autocomplete="off">
        <b-form-input
          v-model="search"
          type="search"
          required
          @blur="hideSearchResultsTimer()"
          :placeholder="$t('search_placeholder')"
          v-on:keyup.enter="goSearch()"
          class="search-input"
          autocomplete="off"
        ></b-form-input>
      </b-form>
      <b-btn v-on:click="goSearch()" class="search-btn" variant="light" squared>
        <ClientOnly>
          <i class="fas fa-search"></i>
        </ClientOnly>
      </b-btn>

      <div class="search-results" :class="{ show: isSearchResultsShown }">
        <span class="search--title">{{ $t("suggestions") }}:</span>
        <ul class="search-suggestions">
          <li
            v-for="suggestion of searchSuggestions"
            :key="suggestion.word"
            class="d-block"
          >
            <b-link v-on:click="goRoute('/search', suggestion.word)">{{
              suggestion.word
            }}</b-link>
          </li>
        </ul>
        <span class="search--title">{{ $t("recommended_products") }}:</span>
        <ul class="recommended-products">
          <li
            v-for="recommendedProduct of recommendedProducts"
            :key="recommendedProduct.sku"
            class="d-inline-block"
          >
            <b-link
              v-on:click="goRoute('/' + recommendedProduct.url_key)"
              class="d-flex align-items-center"
            >
              <div class="product-img">
                <b-img
                  :src="recommendedProduct.thumbnail.small"
                  :alt="recommendedProduct.name"
                  width="41"
                  height="51"
                  fluid
                ></b-img>
              </div>
              <span class="product-name">{{ recommendedProduct.name }}</span>
            </b-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="close-bar" @click="$emit('close-search-m')">
      <b-icon icon="x"></b-icon>
    </div>
  </div>
</template>

<script>
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";
import ClientOnly from "vue-client-only";

export default {
  name: "HeaderSearch",
  components: {
    ClientOnly,
  },
  data: () => ({
    search: "",
    searchlTimer: null,
    seachCloseTimer: null,
    closeSignal: null,
    output: null,
    searchSuggestions: [],
    recommendedProducts: [],
    isSearchGroupShown: false,
    isSearchResultsShown: false,
  }),
  mounted() {
    window.addEventListener("resize", this.resize);
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
    window.removeEventListener("click", this.resize);
  },
  computed: {
    currentTemplate() {
      return config.template.name;
    },
  },
  methods: {
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 575) {
        this.isSearchGroupShown = false;
      }
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
    toggleSearch() {
      this.isSearchGroupShown = !this.isSearchGroupShown;
    },
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.isSearchGroupShown = false;
      }
    },
    showSearchResults() {
      this.isSearchResultsShown = true;
    },
    hideSearchResults() {
      this.isSearchResultsShown = false;
    },
    async getSearchPreview() {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal = controller;
      this.output = await getProductsBySearchPreview(this.search, signal);
      this.closeSignal = null;

      this.recommendedProducts = this.output.items;
      this.searchSuggestions = this.output.search_suggestions;
      this.showSearchResults();
    },
    goRoute(route, query = null) {
      Logger.debug("goRoute", "HeaderSearch", route)();
      this.hideSearchResults();
      this.search = "";
      if (query != null) {
        this.$router.push({ path: route, query: { q: query } });
      } else {
        this.$router.push({ path: route });
      }
    },
    goSearch() {
      this.hideSearchResults();
      if (this.search.length > 0) {
        clearTimeout(this.searchlTimer);
        if (this.closeSignal !== null) this.closeSignal.abort();

        this.$router.push({ path: "/search", query: { q: this.search } });
      }
    },
    goClose() {
      this.close(this);
    },
    hideSearchResultsTimer() {
      Logger.debug("hideSearchResultsTimer", "HeaderSearch", null)();
      this.seachCloseTimer = setTimeout(
        function (scope) {
          scope.search = "";
          scope.hideSearchResults();
        },
        300,
        this
      );
    },
  },
  watch: {
    search() {
      if (this.search.length > 2) {
        if (this.searchlTimer !== null) {
          clearTimeout(this.searchlTimer);
        }
        this.searchlTimer = setTimeout(
          function (scope) {
            if (scope.closeSignal !== null) {
              scope.closeSignal.abort();
            }
            scope.getSearchPreview();
          },
          300,
          this
        );
      } else {
        this.hideSearchResults();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-form {
  flex-grow: 1;
  margin-left: 16px;
  font-size: 20px;
  margin-top: -5px;
}
.search-group {
  position: relative;
  width: 225px;
  margin-left: auto;
  .search-input {
    font-size: 16px;
    padding-right: 33px;
    border-radius: 4px;
    border: 0;
    &::placeholder {
      font-size: 13px;
      letter-spacing: -0.02em;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.search-btn {
  background: none !important;
  border: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  right: 0;
  font-size: 20px;
  padding: 4px 12px;
  svg {
    color: $primaryColor;
    font-size: 16px;
  }
}
.close-btn {
  display: none;
}
.search-results {
  position: absolute;
  // left: 0;
  right: 0;
  top: 90%;
  width: 100%;
  z-index: 999;
  display: none;
  background: $white;
  font-size: 15px;
  padding: 15px 10px 10px 10px;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;

  &.show {
    display: block;
  }
  & > .search--title {
    padding-bottom: 0;
  }
}

.search-suggestions,
.recommended-products {
  list-style: none;
  margin: 0;
}

.search-suggestions {
  border-bottom: 1px solid $borderColor;
  margin-bottom: 10px;
  padding-bottom: 5px;
  li {
    a {
      color: $primaryColor;
      font-size: 12px;
    }
  }
}

.search--title {
  font-size: 12px;
  font-weight: 600;
  display: block;
  color: #0d1f47;
  padding-bottom: 10px;
}

.recommended-products {
  margin-top: 10px;
  .product-img {
    width: 40px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
  .product-name {
    padding-left: 10px;
    color: $primaryColor;
    font-size: 12px;
  }

  li {
    + li {
      margin-top: 10px;
    }
  }
}
</style>
