<template>
  <transition name="back-to-top-fade">
    <client-only>
      <div
        class="vue-back-to-top"
        :style="`bottom:${this.bottom};right:${this.right};`"
        v-show="visible"
        @click="backToTop"
      >
        <slot>
          <i class="fas fa-chevron-up"></i>
        </slot>
      </div>
    </client-only>
  </transition>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "BackToTop",
  components: {
    ClientOnly,
  },
  props: {
    text: {
      type: String,
      default: "Voltar ao topo",
    },
    visibleoffset: {
      type: [String, Number],
      default: 600,
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0,
    },
    right: {
      type: String,
      default: "30px",
    },
    bottom: {
      type: String,
      default: "60px",
    },
    scrollFn: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    window.smoothscroll = () => {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll);
        window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5));
      }
    };
    window.addEventListener("scroll", this.catchScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.catchScroll);
  },
  methods: {
    /**
     * Catch window scroll event
     * @return {void}
     */
    catchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      const pastBottomOffset =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
      this.visible =
        parseInt(this.visibleoffsetbottom) > 0
          ? pastTopOffset && !pastBottomOffset
          : pastTopOffset;
      this.scrollFn(this);
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top-fade-enter-active,
.back-to-top-fade-leave-active {
  transition: opacity 0.7s;
}
.back-to-top-fade-enter,
.back-to-top-fade-leave-to {
  opacity: 0;
}

.vue-back-to-top {
  cursor: pointer;
  position: fixed;
  z-index: 1000;
}

.vue-back-to-top {
  width: 50px;
  height: 50px;
  background-color: $primaryColor;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  color: $white;
  box-shadow: 3px 4px 15px #00000047;
  @media screen and (max-width: 670px) {
    display: none;
    // width: 35px;
    // height: 35px;
    // line-height: 35px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    svg {
      font-size: 17px;
    }
  }
}
</style>
