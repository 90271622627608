<template>
  <div class="header-text" v-if="generalMsg.text != ''">
    <div
      class="top-banner"
      :style="
        `background-color:` +
        generalMsg.background_color +
        `; color:` +
        generalMsg.text_color +
        `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    generalMsg() {
      return this.$store.state.messages.generalMsg;
    },
  },
};
</script>

<style>
</style>