import config from "@config";
const currentTemplate = config.template.name;

export default function getImageUrl(fileName) {
  try {
    return require(`../../${currentTemplate}/assets/imgs/${fileName}`);
  } catch (_) {
    return require(`../../base/assets/imgs/${fileName}`);
  }
}
