<template>
  <ClientOnly>
    <b-collapse :id="collapseId" class="sub-menu" v-model="visible">
      <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
        <li v-for="menuItem in menu" :key="menuItem.id">
          <b-link v-on:click="closeInternal" :to="menuItem.linkTo">
            <i class="fas fa-chevron-right"></i>
            {{ menuItem.name }}
          </b-link>
          <!-- tmp{{ menuItem.dropMenu }} -->
          <ul class="sub-child">
            <b-collapse visible :id="`sub-collaps${menuItem.id}`" class="brands-filter">
              <li v-for="(subItem, i) in menuItem.dropMenu" :key="subItem.id">
                <div v-if="i < 2">
                  <b-link v-on:click="closeInternal" :to="`/${subItem.url_path}`">
                    <i class="fas fa-chevron-right"></i>
                    {{ subItem.name }}
                  </b-link>
                </div>
                <div v-else>
                  <div v-if="readMore[menuItem.id]">
                    <!-- v-if="subItem.include_in_menu" -->
                    <b-link :to="`/${subItem.url_path}`" v-on:click="closeInternal">
                      <i class="fas fa-chevron-right"></i>
                      {{ subItem.name }}
                    </b-link>
                  </div>
                </div>
              </li>
              <div class="more-menu-link" v-if="menuItem.dropMenu && menuItem.dropMenu.length > 2">
                <div v-if="!readMore[menuItem.id]" key="read-more-icon">
                  <b-link @click.stop="showMore(menuItem.id)">
                    {{ $t("show_more") }}
                    <i class="fa fa-chevron-down"></i>
                  </b-link>
                </div>

                <div v-if="readMore[menuItem.id]" key="read-less-icon">
                  <b-link @click.stop="showLess(menuItem.id)">
                    {{ $t("show_less") }}
                    <i class="fa fa-chevron-up"></i>
                  </b-link>
                </div>
              </div>
            </b-collapse>
          </ul>
        </li>
      </ul>
    </b-collapse>
  </ClientOnly>
</template>

<script>
//import { Logger } from "@storefront/core/lib/logger";
import ClientOnly from "vue-client-only";
export default {
  name: "HeaderSubmenu",
  props: ["menuArray", "collapseId", "modelValue", "id", "isHam"],
  components: {
    ClientOnly,
  },
  data: () => ({
    //visible: false,
    me: this,
    readMore: {},
  }),
  computed: {
    visible: {
      get() {
        return this.$store.getters["menu/getMenuItemState"](this.id).state;
      },
      set(val) {
        this.$store.commit("menu/updateMenuState", { id: this.id, state: val });
      },
    },
  },
  methods: {
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
    },
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
        //this.$store.commit("menu/setNavBar", false);
      }
    },
    closeInternal() {
      this.visible = false;
      this.$store.commit("menu/setNavBar", false);
    },
    sortMenu() {
      this.menuArray[0].sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        else {
          return -1;
        }
      })
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.sortMenu();
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
</style>
