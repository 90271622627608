<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    accordion="header-accordion"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop login-user-drop"
    :visible="visible"
  >
    <!-- v-model="visible" -->
    <div>
      <ul class="list-unstyled">
        <li>
          <b-link @click="goAccount">{{ $t("account") }}</b-link>
        </li>
        <li>
          <b-link @click="goLogout">{{ $t("logout") }}</b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
    visible: { type: Boolean },
  },
  mounted() {
    // document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    // document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      // visible: false,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    goAccount() {
      // this.visible = false;
      this.$router.push("/account");
    },
    goLogout() {
      // this.visible = false;
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        // this.visible = false;
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
